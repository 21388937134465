import React from "react";

import defaultLogo from "./default.svg";

import "./style.css";

export const Logo = ({ logo }) => {
  return (
    <div className="Logo">
      <div className="Logo__img">
        <img src={logo || defaultLogo} alt="Payment Gateway" />
      </div>
    </div>
  );
};
