import React from "react";
import { Container } from "reactstrap";

export const Layout = ({ children }) => {
  return (
    <div>
      <Container>{children}</Container>
    </div>
  );
};
