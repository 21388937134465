import React from "react";
import QRCode from "qrcode.react";

import CopyInput from "../CopyInput";

import "./style.css";

export const Widget = ({ id, amount, currency, ticker, address, contractLink, chain }) => {

    let contract;
    
    if (contractLink !== "") {
      switch (chain) {
        case "TRX":
          contract = <a href={contractLink}>in TRC20</a>
          break;

        case "ETH":
          contract = <a href={contractLink}>in ERC20</a>
          break;
      }
    }
    
    return (
    <div className="Widget">
      <div className="Widget__info">
        <h2 className="Widget__title">Pay with cryptocurrency</h2>
        <div className="Widget__data">
          <div className="Widget__cell">
            <div className="Widget__value">{id}</div>
            <div className="Widget__caption">Payment ID</div>
          </div>
          <div className="Widget__cell">
            <div className="Widget__value">
              {`${amount} ${currency} `}
              <span>(+ small fee)</span>
            </div>
            <div className="Widget__caption">Amount to send</div>
          </div>
        </div>
        <div className="Widget__address">
          <p className="Widget__description">
            Address for deposits in {currency} <strong>({ticker})</strong> {contract}
          </p>
          <CopyInput address={address} />
        </div>
      </div>
      <div className="Widget__qr">
        <QRCode value={address} size={184} />
      </div>
    </div>
  );
};
