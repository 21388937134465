import React from "react";
import { Route, Switch } from "react-router";
import { Home, Error, Summary } from "./pages";

import "./custom.css";
import NewPayout from "./pages/Payouts/NewPayout";
import PayoutDetails from "./pages/Payouts/PayoutDetails";

const App = () => {
  return (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/summary" component={Summary} />
        <Route path="/error" component={Error} />
        <Route path="/payouts/:id" component={PayoutDetails} />
        <Route path="/payouts" component={NewPayout} />
    </Switch>
  );
};

export default App;
