import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Alert } from "reactstrap";

import "./style.css";

export const CopyInput = ({ address }) => {
  const [visible, setVisible] = useState(false);

  const handleCopied = () => {
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, 2000);
  };

  return (
    <>
      <div className="CopyInput">
        <input className="CopyInput__input" value={address} readOnly />
        <CopyToClipboard text={address} onCopy={() => handleCopied()}>
          <button type="button" className="CopyInput__copy" />
        </CopyToClipboard>
      </div>
      <Alert
        className="CopyInput__alert"
        color="success"
        isOpen={visible}
        toggle={() => setVisible(false)}
      >
        Address copied successfully!
      </Alert>
    </>
  );
};
