import React from "react";

const Status = ({ status }) => {
  const STATUSES = {
    ErrorPaymentExecution: "Error Payment Execution",
    PaymentExpired: "Payment Expired",
    WaitingForPayment: "Waiting For Payment",
    WaitingForConfirmations: "Waiting For Cinfirmations",
    PaymentIsPaid: "Payment Is Paid",
    PaymentIsFinished: "Payment Is Finished",
    PaymentIsCanceled: "Payment Is Canceled",
  };

  return (
    <div className={`Status Status__${status}`}>
      {STATUSES[status] ?? "Error"}
      {status === "WaitingForPayment" && (
        <div className="Status__loading">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      )}
    </div>
  );
};

export default Status;
