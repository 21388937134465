import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const PayoutDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            axios.get(`/api/payouts/${id}`)
                .then(response => {
                    setData(response.data);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }, 1000);
    }, [id]);

    if (!data) return null;

    return (
        <div className="container mt-3">
            <h1 className="text-center mb-4">Payout Details for Id: {id}</h1>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <p><strong>User Id:</strong> {data.userId}</p>
                            <p><strong>From Wallet:</strong> {data.payout?.fromWallet}</p>
                            <p><strong>To Wallet:</strong> {data.payout?.toWallet}</p>
                            <p><strong>Amount:</strong> {data.payout?.amount}</p>
                            <p><strong>Currency Chain:</strong> {data.payout?.currency?.chain?.name}</p>
                            <p><strong>Currency Ticker:</strong> {data.payout?.currency?.ticker}</p>
                            <p><strong>Currency Id:</strong> {data.currencyId}</p>
                            <p><strong>Company Name:</strong> {data.company?.name}</p>
                            <p><strong>Company Id:</strong> {data.company?.id}</p>
                            <p><strong>Status:</strong> {data.payout?.status}</p>
                            <p><strong>Transaction Hash:</strong> {data.payout?.transactionHash}</p>
                            <p><strong>Success Callback URL:</strong> {data.successCallbackUrl}</p>
                            <p><strong>Error Callback URL:</strong> {data.errorCallbackUrl}</p>
                            <p><strong>Created At:</strong> {data.payout?.createdAt}</p>
                            <p><strong>Updated At:</strong> {data.payout?.updatedAt}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayoutDetails;