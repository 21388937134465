import React from "react";
import { Layout } from "../components/Layout";

const Error = () => {
  return (
    <Layout>
      <h1 id="tabelLabel">Something went wrong</h1>
        <h2 id="tabelLabel">Something went wrong</h2>
    </Layout>
  );
};

export default Error;
