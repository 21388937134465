import React, { useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import axios from "axios";

import { useHistory, useLocation } from "react-router";

import Logo from "../../components/Logo";
import Widget from "../../components/Widget";
import CopyInput from "../../components/CopyInput";
import Status from "../../components/Status";
import Timer from "../../components/Timer";
import DynamicHead from "../../components/DynamicHead";

import "./style.css";

const Summary = () => {
  const [data, setData] = useState({});
  const [isLoading, setisLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => queryString.parse(location.search), [
    location.search,
  ]);

  useEffect(() => {
    const getPayment = async () => {
      setisLoading(true);
      try {
        const { data } = await axios.get(`/bills/${params.id}`);
        setData(data);
      } catch (error) {
        history.push("/error");
      } finally {
        setisLoading(false);
      }
    };

    getPayment();
  }, [history, params.id]);

  const hasData = !!Object.keys(data).length;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!hasData) {
    return <div>Error has occurred...</div>;
  }

  return (
    <div className="Summary">
      <DynamicHead currency={data.currency} logo={data.companyIcon} icon={data.shortcutIcon} />
      <div className="Summary__header">
        <Logo logo={data.companyIcon} />
      </div>
      <div className="Summary__content">
        <div className="container">
          <h1 className="Summary__title">{data.currency} Payment Gateway</h1>
          <div className="Summary__status">
            <div className="Summary__timer">
              {data.status === "WaitingForPayment" && (
                <Timer timeLeft={data.timeLeft} />
              )}
            </div>
            <Status status={data.status} />
          </div>
          <div className="Summary__widget">
            <Widget
              id={data.id}
              amount={data.amount}
              currency={data.currency}
              ticker={data.ticker}
              address={data.address}
              contractLink={data.contractLink}
              chain={data.chain}
            />
          </div>
          <div className="Summary__instruction">
            <h2 className="Summary__instruction-title">Pay by card</h2>
            <span className="Summary__sub">
              Payment is made in a third-party service
            </span>
            <p className="Summary__text">
              If you don’t have cryptocurrency, you can use a recommended
              exchange service and pay by card.
            </p>
            <ol className="Summary__list">
              <li className="Summary__list-item">
                Go to one of the recommended exchange platforms using the
                provided links. Paste the following address into the wallet
                address field
              </li>
              <li className="Summary__list-item">
                Enter the purchase amount and fill in all fields. In the wallet
                address field - copy and specify this address
                <CopyInput address={data.address} />
              </li>
              <li className="Summary__list-item">
                Pay for the cryptocurrency with you bank card and wait for the
                funds to be credited to your balance.
              </li>
            </ol>
            <div className="Summary__services">
              <h3 className="Summary__services-title">
                Fast and convenient exchange services:
              </h3>
              <div className="Summary__services-list">
                <a
                  href="https://prostocash.com/"
                  className="Summary__services-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ProstoCash
                </a>
                <a
                  href="https://coinshop24.org/"
                  className="Summary__services-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  coinshop24
                </a>
                <a
                  href="https://365cash.co/"
                  className="Summary__services-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  365Cash
                </a>
              </div>
              <div className="Summary__services-list">
                <a
                  href="https://exbase.io/"
                  className="Summary__services-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EXBASE.IO
                </a>
                <a
                  href="https://7money.co/"
                  className="Summary__services-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  7money
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Summary__footer">
        <div className="container">
          <div className="Footer">
            <Logo logo={data.companyIcon} />
            <div className="Footer__text">
              If you have any problems or questions, please contact your broker.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
