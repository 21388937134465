import React from "react";
import { Helmet } from "react-helmet";

const DynamicHead = ({ currency, logo, icon }) => (
  <Helmet>
    <title>{currency} Payment Gateway</title>
        {logo && <link rel="shortcut icon" href={icon} />}
  </Helmet>
);

export default DynamicHead;
