import React from "react";
import ReactTimer from "react-compound-timer";

import "./style.css";

export const Timer = ({ timeLeft }) => {
  return (
    <div className="Timer">
      <span className="Timer__text">Time left to send the money</span>

      <ReactTimer
        initialTime={timeLeft * 1000}
        direction="backward"
        className="Timer__value"
      >
        {() => (
          <div className="Timer__value">
            <ReactTimer.Hours />h{` `}
            <ReactTimer.Minutes />m {` `}
            <ReactTimer.Seconds />s {` `}
          </div>
        )}
      </ReactTimer>
    </div>
  );
};
