import React, { useMemo, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";

import { Layout } from "../components/Layout";

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => queryString.parse(location.search), [
    location.search,
  ]);

  useEffect(() => {
    const createPayment = async () => {
      try {
        const { data } = await axios.post("bills", params);
        history.push(`/summary?id=${data.id}`);
      } catch (error) {
        history.push("/error");
      }
    };
    createPayment();
  }, [params, history]);

  return (
    <Layout>
      <div> Loading... </div>
    </Layout>
  );
};

export default Home;
