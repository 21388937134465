import React, {useEffect, useState} from 'react';
import "./style.css";
import axios from "axios";
import {useHistory} from "react-router-dom";

const NewPayout = () => {
    const history = useHistory();
    
    const [companies, setCompanies] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    
    const [data, setData] = useState({
        currencyId: "",
        companyId: "",
        amount: 0,
        toAddress: "",
        callbackUrl: "http://localhost:4001/callback",
        userId: "test_bogdan",
        trackingId: "999-123",
        hash: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        axios.get('api/company')
            .then(response => setCompanies(response.data))
            .catch(err => console.log(err));
        axios.get('api/payouts/quotes')
            .then(response => setCurrencies(response.data))
            .catch(err => console.log(err));
    }, []);
    
    const handleChange = (event) =>{
        console.log(event.target.name + " " + event.target.value);
        setData({
            ...data,
            [event.target.name] : event.target.value
        });
    }

    const getHash = async () => {
        try {
            const response = await axios.post(`/api/payouts/encrypt`, data);
            setData({
                ...data,
                hash: response.data
            });
            return response.data;
        } catch (error) {
            return null;
        }
    }
    
    const create = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await getHash();
            const response = await axios.post(`/api/payouts`, data);
            
            history.push(`/payouts/${response.data}`)
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="container-fluid">
            <h2>New Payout</h2>
            <div className="payout-form">
                <form>
                    <div className="row">
                        <div className="col-6">
                            <label className="form-label">
                                Company:
                            </label>
                            <label className="form-label">
                                Currency:
                            </label>
                            <label className="form-label">
                                Amount:
                            </label>
                            <label className="form-label">
                                Destination Address:
                            </label>
                            <label className="form-label">
                                Callback URL:
                            </label>
                            <label className="form-label">
                                User ID:
                            </label>
                            <label className="form-label">
                                Tracking ID :
                            </label>
                        </div>
                        <div className="col-6">
                            <div>
                                <select className="form-input" name="companyId" value={data.companyId}
                                        onChange={handleChange}>
                                    <option value="">Select a company</option>
                                    {companies.map((company, index) => (
                                        <option key={index} value={company.id}>{company.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select className="form-input" name="currencyId" value={data.currencyId}
                                        onChange={handleChange}>
                                    <option value="">Select currency</option>
                                    {currencies.map((currency, index) => (
                                        <option key={index} value={currency.currencyId}>{currency.ticker}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <input className="form-input" type="number" name="amount" value={data.amount}
                                       onChange={handleChange}/>
                            </div>
                            <div>
                                <input className="form-input" type="text" name="toAddress"
                                       value={data.toAddress} onChange={handleChange}/>
                            </div>
                            <div>
                                <input className="form-input" type="text" name="callbackUrl" value={data.callbackUrl}
                                       onChange={handleChange}/>
                            </div>
                            <div>
                                <input className="form-input" type="text" name="userId" value={data.userId}
                                       onChange={handleChange}/>
                            </div>
                            <div>
                                <input className="form-input" type="text" name="trackingId" value={data.trackingId}
                                       onChange={handleChange}/>
                            </div>
                            <div>
                                <button onClick={create}>
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewPayout;